<template>
  <div id="graph">
    <div class="select">
      <v-select
        :options="partyList"
        :reduce="(party) => party.code"
        label="name"
        v-model="defaultSelected"
        :value="party"
        dense
        solo
        :clearable="false"
        @input="changeParty"
      />
    </div>
    <sankeyGR24 v-if="renderSankey" :party="party"></sankeyGR24>
  </div>
</template>
<script>
import Bars from "@/components/Bars.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import SankeyGR24 from "../components/SankeyGR24.vue";

export default {
  name: "Table",
  components: {
    Bars,
    vSelect,
    SankeyGR24,
  },
  methods: {
    changeParty(party) {
      this.party = party;

      this.renderSankey = false;

      this.$nextTick(() => {
        this.renderSankey = true;
      });
    },
  },
  data() {
    return {
      party: "SP",
      renderSankey: true,
      defaultSelected: {
        name: "Sozialdemokratische Partei",
        code: "SP",
      },
      partyList: [
        {
          name: "Sozialdemokratische Partei",
          code: "SP",
        },
        {
          name: "Schweizerische Volkspartei",
          code: "SVP",
        },
        {
          name: "LDP Liberal-Demokratische",
          code: "LDP",
        },
        {
          name: "Grüne",
          code: "GRÜNE",
        },
        {
          name: "FDP.Die Liberalen",
          code: "FDP",
        },
        {
          name: "Grünliberale",
          code: "GLP",
        },
        {
          name: "Grünliberale",
          code: "GLP",
        },
        {
          name: "BastA!",
          code: "BastA",
        },
        {
          name: "Die Mitte",
          code: "Mitte",
        },
        {
          name: "Evangelische Volkspartei",
          code: "EVP",
        },
        {
          name: "Partei der Arbeit",
          code: "PdA",
        },
        {
          name: "Volks-Aktion",
          code: "VA",
        },
        {
          name: "Für Soziales und Sport",
          code: "FSSK",
        },
        {
          name: "Parteiloser Bürger",
          code: "PBkW",
        },
        {
          name: "Ein KUSS für Basel",
          code: "KUSS",
        },
      ],
    };
  },
};
</script>
<style scoped>
#graph {
  margin-top: 50px;
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.select {
  margin-top: 50px;
  width: 100%;
  margin-bottom: 15px;
}
.v-select {
  background: white !important;
  width: 50%;
}
</style>
